import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import category from "./category";
import blog from "./blog";
import charity from "./charity";
import project from "./project";
import general from "./general";
import subscribers from "./subscribers";
import about from "./about";
import companies from "./companies";
import slider from "./slider";


export default combineReducers({
    auth,
    utils,
    languages,
    media,
    composite,
    moderators,
    staticTexts,
    category,
    blog,
    charity,
    general,
    subscribers,
    about,
    companies,
    project,
    slider,
});
