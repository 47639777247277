// Import packages
import React, { Component } from "react";
import { connect } from "react-redux"
import moment from "moment";
import { Switch, DatePicker } from "antd";

//import styles

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { UpdateBlogItemData, CreateBlogItem, getBlogById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import { Editor } from "../../components/uiElements/Editor";

class AddEditBlog extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'details',
            'summary',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                date: null,
                slug: '',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getDate = this.getDate.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditBlog = this.addEditBlog.bind(this);

    }

    async componentDidMount () {
        const { id } = this.props;
        const { isEditing, fieldsData } = this.state;

        if (isEditing && !id) {
            history.push('/blog');
            return;
        }

        if (isEditing) {
            const translationsData = {};
            const editingData = await this.props.getBlogById(id)

            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                        details: trItem?.details || '',
                        summary: trItem?.summary || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                slug: editingData?.slug || '',
                date: editingData?.date ? moment(editingData?.date) : '',
                mediaMain: editingData?.mediaMain,
                isHidden: editingData?.isHidden,
            };

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    getDate (value) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.updatedDataMap.set('date', value)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                date: value,
            },
            errors: {
                ...errors,
                date: false
            }
        })
    }

    async addEditBlog () {
        const { id } = this.props;
        await this.setState({
            requestLoading: true
        });
        const { translations, mediaMain, slug, date, isHidden } = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!date) {
            errors.date = true;
            result = false;
        }
        if (!slug) {
            errors.slug = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    slug,
                    date,
                    mediaMain: mediaMain.id,
                    translations: getTranslationData(translations)
                };
                console.log('reqData', reqData);
                this.props.CreateBlogItem(reqData).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateBlogItemData(id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render () {
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բլոգ' : 'Փոփոխել Բլոգը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                        // customDelete={this.deleteMedia}
                    />
                </div>}
                <InputGroup inputType={'wrapper'} label={'Ամսաթիվը'}
                            error={errors.date}
                            required={true}
                            value={fieldsData.date}>
                    <DatePicker value={fieldsData.date}
                                format="DD/MM/YY"
                                placeholder={'DD/MM/YYYY'}
                                showToday={false}
                                allowClear={true}
                                className={`input-time ${errors.date ? 'error' : ""}`}
                                onChange={this.getDate}/>
                </InputGroup>

                <div className="info-wrapper">
                    <InputGroup inputType="input"
                                name="slug"
                                label={"Սլագ"}
                                placeholder={'Սլագ'}
                                value={fieldsData.slug}
                                initValue={this.currentData?.slug}
                                required={true}
                                error={errors['slug']}
                                onChange={this.getInputValues}
                    />
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                required={true}
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    <div className="editor-wrapper">
                        <label>Մանրամասներ</label>
                        <Editor value={currentData?.details}
                                name={'details'}
                                initValue={initData.details}
                                error={errors['details' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                    <div className="editor-wrapper">
                        <label>Ամփոփում</label>
                        <Editor value={currentData?.summary}
                                name={'summary'}
                                initValue={initData.summary}
                                error={errors['summary' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditBlog}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    getBlogById,
    CreateBlogItem,
    UpdateBlogItemData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBlog)
