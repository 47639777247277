import { AUTH_CONSTS, SLIDER_CONSTS } from "../constants";

export const initialState = {
    sliderList: [],
    sliderById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SLIDER_CONSTS.GET_SLIDERS:
            return {
                ...state,
                sliderList: action.payload || [],
            };
            case SLIDER_CONSTS.GET_SLIDER_BY_ID:
            return {
                ...state,
                sliderById: action.payload || [],
            };
        case SLIDER_CONSTS.CREATE_SLIDER:
            return {
                ...state,
                sliderList: [action.payload].concat(state.sliderList)
            };
        case SLIDER_CONSTS.DELETE_SLIDER_BY_ID:
            return {
                ...state,
                sliderList: state.sliderList.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
