// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    UpdateCompaniesData,
    CreateCompaniesItem, GetCompaniesById,
} from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import { NestableItem } from "../../components/cards/nestableItem";
import { Switch } from "antd";
import { history } from "../../configs/history";
import {Editor} from "../../components/uiElements/Editor";

class AddEditCompanies extends Component {
    constructor (props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
                slug: undefined,
                link: undefined,
                productLink: undefined,
                newsLink: undefined,
                year: undefined,
                workers: undefined,
                contented: undefined,
                isHidden: false,
                products: [],
                news: [],

            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.deletePartners = this.deletePartners.bind(this);
        this.addEditCompany = this.addEditCompany.bind(this);
    }

    async componentDidMount () {
        const { id } = this.props;
        const { isEditing, fieldsData } = this.state;

        if (isEditing) {
           await this.props.GetCompaniesById(id)
            const {companiesById } = this.props;
            const translationsData = {};
            if (!!companiesById) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = companiesById?.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: companiesById?.mediaMain,
                slug: companiesById?.slug || '',
                link: companiesById?.link || '',
                productLink: companiesById?.productLink || '',
                newsLink: companiesById?.newsLink || '',
                year: companiesById?.year || '',
                workers: companiesById?.workers || '',
                contented: companiesById?.contented || '',
                isHidden: companiesById?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia (mediaArray) {
        const { fieldsData, errors, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia () {
        const { fieldsData, isEditing } = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditCompany () {
        const { id } = this.props;
        await this.setState({
            requestLoading: true
        });
        const {
            translations,
            mediaMain,
            slug,
            isHidden,
            link,
            productLink,
            newsLink,
            year,
            news,
            products,
            workers,
            contented
        } = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = { ...validationTr.errors };

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (!slug) {
            errors.slug = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    slug,
                    link,
                    productLink,
                    newsLink,
                    year,
                    workers,
                    contented,
                    news,
                    products,
                    mediaMain: mediaMain.id,
                    translations: getTranslationData(translations)
                };
                // console.log(reqData,'Data')
                this.props.CreateCompaniesItem(reqData).then((company) => {
                    history.push(`/companies/edit/${company.id}`)
                    this.setState({
                        isEditing: true,
                        errorsTabs: [],
                        requestLoading: false,
                    })
                }).finally(() => {
                    this.setState({ requestLoading: false });
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateCompaniesData(id, reqData)
                    .then(() => {
                        history.push('/companies')
                    })
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getNestableItems (list, slug = null) {
        const { mainLanguage, id } = this.props;
        const items = [];
        list && !!list.length && list.map((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: index,
                index: index,
                title: title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/companies/edit/${id}/${slug}/${index}`,
                    state: {
                        slug: `${slug}`,
                        index: `${index}`
                    },
                },
                deleteCb: deleteItem.bind(this, this.deletePartners, 'Տվյալ', index, slug),
                children: []
            })
        });
        return items;
    }

    deletePartners (index, slug) {
        const { id, companiesById } = this.props;
        let data = companiesById[slug].map(item => {
            return {
                ...item,
                mediaMain: item?.mediaMain?.id
            }
        })
        let reqData = []
        if (slug === 'news') {
            reqData = {
                news: data.filter((item, num) => num !== index)
            }
        } else {
            reqData = {
                products: data.filter((item, num) => num !== index)
            }
        }
        this.props.UpdateCompaniesData(id, reqData)
        this.props.GetCompaniesById(id)
    }

    render () {
        const { companiesById } = this.props;
        const { fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData?.translations[languageTab] || {};
        let editData = isEditing ? companiesById : this.state.fieldsData

        let newsItems = this.getNestableItems(editData?.news, 'news');
        let productsItems = this.getNestableItems(editData?.products, 'products');

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ընկերություն' : 'Փոփոխել Ընկերություն'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <div className={'links-wrapper'}>
                        <InputGroup inputType="input"
                                    name="slug"
                                    label={"Սլագ"}
                                    placeholder={'Սլագ'}
                                    value={fieldsData.slug}
                                    initValue={this.currentData?.slug}
                                    required={true}
                                    error={errors['slug']}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType="input"
                                    name="link"
                                    label={"Հղում"}
                                    placeholder={'Հղում'}
                                    value={fieldsData.link}
                                    initValue={this.currentData?.link}
                                    error={errors['link']}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType="input"
                                    name="productLink"
                                    label={"Ապրանքի հղում"}
                                    placeholder={'Ապրանքի հղում'}
                                    value={fieldsData.productLink}
                                    initValue={this.currentData?.productLink}
                                    error={errors['productLink']}
                                    onChange={this.getInputValues}
                        />
                        <InputGroup inputType="input"
                                    name="newsLink"
                                    label={"Նորությունների հղում"}
                                    placeholder={'Նորությունների հղում'}
                                    value={fieldsData.newsLink}
                                    initValue={this.currentData?.newsLink}
                                    error={errors['newsLink']}
                                    onChange={this.getInputValues}
                        />
                    </div>
                    <InputGroup inputType="input"
                                type={'text'}
                                name="year"
                                label={"Շուկայում"}
                                placeholder={'Շուկայում'}
                                value={fieldsData.year}
                                initValue={this.currentData?.year}
                                error={errors['year']}
                                onChange={this.getInputValues}
                    />
                    <InputGroup inputType="input"
                                type={'text'}
                                name="workers"
                                label={"Աշխատակիցներ"}
                                placeholder={'Աշխատակիցներ'}
                                value={fieldsData.workers}
                                initValue={this.currentData?.workers}
                                error={errors['workers']}
                                onChange={this.getInputValues}
                    />
                    <InputGroup inputType="input"
                                type={'text'}
                                name="contented"
                                label={"Գոհ հաճախորդներ"}
                                placeholder={'Գոհ հաճախորդներ'}
                                value={fieldsData.contented}
                                initValue={this.currentData?.contented}
                                error={errors['contented']}
                                onChange={this.getInputValues}
                    />
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <div className={'editor-wrapper'}>
                        <label>Նկարագրություն</label>
                        <Editor value={currentData.description || ""}
                                name={'description'}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                onChange={this.getTranslationValues}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCompany}/>
                </div>

                {isEditing && <>
                    <label className={'list-label'}>Նորություններ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/companies/${this.props.id}/news/add`}/>
                    {newsItems && newsItems.length !== 0 &&
                    <Nestable
                        items={newsItems}
                        maxDepth={1}
                        handler={() => {
                        }}
                        renderItem={NestableItem}
                    />}

                    <label className={'list-label'}>Ապրանքներ</label>
                    <PageHeader linkTitle={"Ավելացնել"}
                                addingLink={`/companies/${this.props.id}/products/add`}/>
                    {productsItems && productsItems.length !== 0 &&
                    <Nestable
                        items={productsItems}
                        maxDepth={1}
                        handler={() => {
                        }}
                        renderItem={NestableItem}
                    />}
                </>}
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCompaniesItem,
    UpdateCompaniesData,
    GetCompaniesById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCompanies)
