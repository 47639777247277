export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        required:true,
        maxLength: 500
    },
    {
        label: "Հեռախոս",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս",
        name: "phoneNumber",
        required:true,
        maxLength: 20
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Facebook",
        name: "facebook",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Linkedin",
        name: "linkedin",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Instagram",
        name: "instagram",
        maxLength: 1000
    },
    // {
    //     label: "Viber",
    //     inputType: "input",
    //     type: "text",
    //     placeholder: "viber",
    //     name: "viber",
    //     maxLength: 500
    // },
    // {
    //     label: "Whatsapp",
    //     inputType: "input",
    //     type: "text",
    //     placeholder: "whatsapp",
    //     name: "whatsapp",
    //     maxLength: 500
    // },
    // {
    //     label: "Telegram",
    //     inputType: "input",
    //     type: "text",
    //     placeholder: "telegram",
    //     name: "telegram",
    //     maxLength: 500
    // },
];
