import {AUTH_CONSTS, GENERAL_CONSTS, SETTINGS_CONSTS} from "../constants";

export const initialState = {
    routesList: [],
    settings:null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.SET_ROUTES:
            return {
                ...state,
                routesList: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                routesList: []
            }
        case GENERAL_CONSTS.GET_SETTINGS:
            console.log(action.payload,'payload')
            return {
                ...state,
                settings: action.payload.charityIsActive,
            }
        default:
            return state;
    }
}