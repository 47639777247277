import { COMPANIES_CONSTS } from "../constants";

export const initialState = {
    companiesData: null,
    companiesById: null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case COMPANIES_CONSTS.SET_COMPANIES_DATA:
        case COMPANIES_CONSTS.CREATE_COMPANIES_ITEM:
            return {
                ...state,
                companiesData: action.payload,
            };
        case COMPANIES_CONSTS.GET_COMPANIES_BY_ID:
            return {
                ...state,
                companiesById: action.payload,
            };
        case COMPANIES_CONSTS.DELETE_COMPANIES_ITEM:
            return {
                ...state,
                companiesData: {
                    ...state.companiesData,
                    companiesData: state.companiesData.filter(item => item.id !== action.payload)
                },
            };

        default:
            return state;
    }
}
