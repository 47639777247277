// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
// import {Editor} from "../components/uiElements/Editor";

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'address',
            'title',
            'description',
            'presentationFile',
        ]
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                phoneNumber: "",
                facebook: "",
                linkedin: "",
                instagram: "",

                viber: "",
                whatsapp: "",
                telegram: "",
                latitude: 0,
                longitude: 0,
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
            mediaModalOpen: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.togglePresentationFileModal = toggleStateField.bind(this, 'presentationFileModalOpen');
        this.getPresentationFile = this.getPresentationFile.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }


    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.languageId === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address: trItem?.address || '',
                title: trItem?.title || '',
                description: trItem?.description || '',
                presentationFile: trItem?.presentationFile || '',
            };
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                phoneNumber: contactData.phoneNumber || "",
                facebook: contactData.facebook || "",
                linkedin: contactData.linkedin || "",
                instagram: contactData.instagram || "",
                translations: translationsData,

                latitude: contactData?.location?.latitude || "",
                longitude: contactData?.location?.longitude || "",
                viber: contactData.viber || "",
                whatsapp: contactData.whatsapp || "",
                telegram: contactData.telegram || "",
            },
        });
    }


    validateFields(constacData) {
        const translationRequiredFields = ['address','title', 'description'];
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (!constacData?.email) {
            errors.email = true;
            result = false;
        }
        if (!constacData?.phoneNumber ||  !phoneNumberRegExp.test(constacData.phoneNumber)) {
            errors.phoneNumber = true;
            result = false;
        }
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    getPresentationFile(presentationFile) {
        const {fieldsData,languageTab} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        presentationFile: presentationFile[0],
                    }
                }
            },
        })
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations);

            let reqData = {
                email: fieldsData.email,
                phoneNumber: fieldsData.phoneNumber,
                facebook: fieldsData.facebook,
                linkedin: fieldsData.linkedin,
                instagram: fieldsData.instagram,
                translations: TR_data,

                // viber: fieldsData.viber,
                // whatsapp: fieldsData.whatsapp,
                // telegram: fieldsData.telegram,
                // location: {
                //     latitude: fieldsData?.latitude,
                //     longitude: fieldsData?.longitude,
                // },
            };
            //console.log('reqData', reqData);
            //
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs,presentationFileModalOpen} = this.state;

        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.languageId === languageTab) || {};

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact">
                {/*<div className="left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Լայնություն"
                                placeholder="Լայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={90}
                                minValue={-90}
                                name="latitude"
                                required={true}
                                value={fieldsData?.latitude}
                                error={errors['latitude']}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Երկայնություն"
                                placeholder="Երկայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={180}
                                minValue={-180}
                                name="longitude"
                                required={true}
                                value={fieldsData?.longitude}
                                error={errors['longitude']}
                                onChange={this.getInputValues}/>
                </div>*/}

                <div className="info-adding-fields">
                    {ContactAddingList.slice(0, 5).map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    {/*<div className="social-links">
                        <label>Սոցիալական հղումներ</label>
                        {ContactAddingList.slice(5).map((item, index) => {
                            return <InputGroup key={index}
                                               {...item}
                                               error={errors[item.name]}
                                               value={fieldsData?.[item.name]}
                                               initValue={contact?.[item.name]}
                                               onChange={this.getInputValues}/>
                        })}
                    </div>*/}
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className={'label-and-action'}>
                    <label>Կցել Presentation File</label>
                    <LinkButton className={"media-select-btn bg-white"}
                                title={"Ընտրել PDF ֆայլ"}
                                cb={this.togglePresentationFileModal}/>
                </div>
                <div className={'contract-wrapper'}>
                    <div className={'wrapper'}>
                        <span>Անվանում՝ {fieldsData.translations[languageTab]?.presentationFile?.name || initTranslations?.presentationFile?.name}</span>
                    </div>
                </div>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address"
                            maxLength={100}
                            value={trData?.address}
                            required={true}
                            initValue={initTranslations?.address}
                            error={errors['address1' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Վերնագիր'}
                            name="title"
                            maxLength={100}
                            value={trData?.title}
                            required={true}
                            initValue={initTranslations?.title}
                            error={errors['title' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Ենթավերնագիր'}
                            name="description"
                            maxLength={100}
                            value={trData?.description}
                            required={true}
                            initValue={initTranslations?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                {/*<div className="editor-wrapper">
                    <label>Գաղտնիության քաղաքականություն <span className={'required-badge'}>*</span></label>
                    <Editor value={trData?.privacyPolicy || ''}
                            name={'privacyPolicy'}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                </div>*/}
                {/*                <InputGroup inputType="textarea"
                            label={<>Գաղտնիության քաղաքականություն<span
                                className="field-required">*</span></>}
                            name="privacyPolicy"
                            value={trData.privacyPolicy}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>*/}
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                // disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!presentationFileModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getPresentationFile}
                appendMedias={fieldsData.presentationFile}
                closeModal={this.togglePresentationFileModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
