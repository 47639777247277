// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetSliders,
    DeleteSliderById,
    UpdateSlidersPositions
} from "../../redux/actions";


class Slider extends Component {
    constructor(props) {
        super(props);
        this.deleteSlider = deleteItem.bind(this, props.DeleteSliderById, 'սլայդերը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        this.props.GetSliders();
    }

    getNestableItems(list, parent = null) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `dashboard/edit/${item.id}`,
                },
                deleteCb: () => this.deleteSlider(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {sliderList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~sliderList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateSlidersPositions(positionedItems);
            this.props.GetSliders();
        }
    }

    render() {
        let {sliderList} = this.props;
        let items = this.getNestableItems(sliderList);
        return <PageWrapper pageTitle={'Սլայդեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/dashboard/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    onChange={this.onPositionChange}
                    renderItem={NestableItem}
                />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSliders,
    DeleteSliderById,
    UpdateSlidersPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
