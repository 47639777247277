// //----------------------------------Official-server---------------------------------------------------

const OFFICIAL_URLS = {
    ADMIN_URL: "https://app.puzzlegroup.am/admin/api/v1",
    HOST_MEDIA: "https://app.puzzlegroup.am/admin",
}
//
// const OFFICIAL_URLS = {
//     ADMIN_URL: "http://37.60.224.224:3201/api/v1",
//     HOST_MEDIA: "http://37.60.224.224:3201",
// }

//--------------------------------------- 4Steps-server --------------------------------------------

const TESTING_URLS = {
    ADMIN_URL: "http://173.249.20.192:3451/api/v1",
    HOST_MEDIA: "http://173.249.20.192:3451",
}

const { ADMIN_URL, HOST_MEDIA } =
    OFFICIAL_URLS;
// TESTING_URLS;

export const HOST_MEDIA_URL = HOST_MEDIA;



export const _urlJwt = ADMIN_URL + "/jwt";
export const _urlMedia = ADMIN_URL + "/files";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlProject = ADMIN_URL + "/projects";
export const _urlBlog = ADMIN_URL + "/news";
export const _urlCharity = ADMIN_URL + "/charities";
export const _urlSubscribers = ADMIN_URL + "/subscribers";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlCompanies = ADMIN_URL + "/companies";
export const _urlMembers = ADMIN_URL + "/members";
export const _urlCallModel = ADMIN_URL + "/call-request";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlSettings = ADMIN_URL + "/settings";

