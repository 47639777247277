import {GENERAL_CONSTS} from "../constants";
import {_urlSettings, request} from "../api";

export const SetRoutes = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.SET_ROUTES,
            payload: data
        });

    }
};

export const GetSettings = () => {
    const requestData = {
        url: _urlSettings,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: GENERAL_CONSTS.GET_SETTINGS,
                    payload: data
                });
            })
    }
};

export const UpdateSettings = (data) => {
    const requestData = {
        url: `${_urlSettings}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GENERAL_CONSTS.UPDATE_SETTINGS,
                    payload: res.data
                });
            })
            .catch(() => {
            })
    }
};