export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - home",
                key: "header_item_home"
            },
            {
                label: "Header - companies",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - news",
                key: "header_item_news"
            },
            {
                label: "Header - staff",
                key: "header_item_staff"
            },
            {
                label: "Header - staff",
                key: "header_item_contact"
            },
            {
                label: "Header - charities",
                key: "header_item_charities"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - slider download",
                key: "homepage_slider_download"
            },
            {
                label: "Homepage - main title",
                key: "homepage_main_block_title"
            },
            {
                label: "Homepage - main description",
                key: "homepage_main_block_description"
            },
            {
                label: "Homepage - main info",
                key: "homepage_main_info"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact description",
                key: "homepage_contact_description"
            },
            {
                label: "Homepage - contact placeholder email",
                key: "homepage_contact_placeholder_email"
            },
            {
                label: "Homepage - see more",
                key: "see_more"
            },
        ]


    },
    about:{
      pageName:"About",
      list:[
          {
              label:"About - slider title",
              key:"about_page_slider_title"
          },
          {
              label:"About - partners title",
              key:"about_page_partners_title"
          },
          {
              label:"About - slider description",
              key:"about_page_slider_description"
          },
      ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section companies",
                key: "footer_section_services"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section news",
                key: "footer_section_news"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - section companies title",
                key: "footer_section_services_title"
            },
            {
                label: "Footer - section correspond",
                key: "footer_section_correspond"
            },
            {
                label: "Footer - section number",
                key: "footer_section_number"
            },
            {
                label: "Footer - section call",
                key: "footer_section_call"
            },
            {
                label: "Footer - section projects title",
                key: "footer_section_projects_title"
            },
            {
                label: "Footer - section MobileID",
                key: "footer_section_MobileID"
            },
            {
                label: "Footer - section copyright",
                key: "footer_section_copyright_text"
            },
            {
                label: "Footer - section developed",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - section presentation file text",
                key: "footer_section_presentationFile_text"
            },
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Blog - Page subscribe title",
                key: "blog_page_subscribe_title"
            },
            {
                label: "Blog - Page subscribe description",
                key: "blog_page_subscribe_description"
            },
            {
                label: "Blog - Page last news",
                key: "blog_page_last_news_title"
            },
            {
                label: "Blog - Page all news",
                key: "blog_page_all_news_title"
            },
            {
                label: "Blog - summery",
                key: "blog_page_summery"
            },
        ]
    },
    charities: {
        pageName: 'Charities',
        list: [
            {
                label: "Charities - Page subscribe title",
                key: "charities_page_subscribe_title"
            },
            {
                label: "Charities - Page subscribe description",
                key: "charities_page_subscribe_description"
            },
            {
                label: "Charities - Page last news",
                key: "charities_page_last_news_title"
            },
            {
                label: "Charities - Page all news",
                key: "charities_page_all_news_title"
            },
            {
                label: "Charities - page all photo",
                key: "charities_page_all_photo"
            },
        ]
    },
    companies: {
        pageName: 'Companies',
        list: [
            {
                label: "Companies - Page products title",
                key: "companies_page_products_title"
            },
            {
                label: "Companies - Page products description",
                key: "companies_page_products_description"
            },
            {
                label: "Companies - Page news title",
                key: "companies_page_news_title"
            },
            {
                label: "Companies - Page news description",
                key: "companies_page_news_description"
            },
            {
                label: "Companies - Page achievements title",
                key: "companies_page_achievements_title"
            },
            {
                label: "Companies - Page achievements description",
                key: "companies_page_achievements_description"
            },
            {
                label: "Companies - Page achievements years",
                key: "companies_page_achievements_years"
            },
            {
                label: "Companies - Page achievements workers",
                key: "companies_page_achievements_workers"
            },
            {
                label: "Companies - Page achievements contented",
                key: "companies_page_achievements_contented"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Page title",
                key: "contacts_page_title"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
            {
                label: "Contacts - Page bloke1 title",
                key: "contacts_page_info_bloke1_title"
            },
            {
                label: "Contacts - Page bloke2 title",
                key: "contacts_page_info_bloke2_title"
            },
            {
                label: "Contacts - Page bloke3 title",
                key: "contacts_page_info_bloke3_title"
            },
            {
                label: "Contacts - Page main title",
                key: "contacts_page_main_title"
            },
            {
                label: "Contacts - Page main description",
                key: "contacts_page_main_description"
            },
            {
                label: "Contacts - Page info subTitle",
                key: "contacts_page_info_subTitle"
            },
            {
                label: "Contacts - Page info title",
                key: "contacts_page_info_title"
            },
        ]
    },
}