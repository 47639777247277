// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    DeleteCompaniesItem,
    GetCompaniesData
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {deleteItem} from "../../utils/helperFunctions";


class Companies extends Component {
    constructor(props) {
        super(props);
        this.deleteCompaniesById = this.deleteCompaniesById.bind(this);
    }

    componentDidMount() {
        this.props.GetCompaniesData();
    }

    deleteCompaniesById(id){
        this.props.DeleteCompaniesItem(id).then(()=>{
            this.props.GetCompaniesData();
        })
    }

    getNestableItems(list, parent = null) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `companies/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.deleteCompaniesById, 'Ընկերություն', item.id),
                children: []
            })
        });
        return items;
    }


    render() {
        let {companiesData} = this.props;
        let items = this.getNestableItems(companiesData);
        return <PageWrapper pageTitle={'Ընկերություններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/companies/add"}/>
            {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={3}
                    handler={()=>{}}
                    renderItem={NestableItem}
                />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCompaniesData,
    DeleteCompaniesItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
