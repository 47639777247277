import {request, _urlSlider, _urlCompanies} from "../api";
import {COMPANIES_CONSTS, SLIDER_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetSliders = () => {
    const requestData = {
        url: _urlSlider,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SLIDER_CONSTS.GET_SLIDERS,
                    payload: data
                });
            })
    }
};

export const GetSliderById = (id) => {
    const requestData = {
        url: `${_urlSlider}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SLIDER_CONSTS.GET_SLIDER_BY_ID,
                    payload: data
                });
            })
    }
};


export const DeleteSliderById = id => {
    const requestData = {
        url:  `${_urlSlider}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SLIDER_CONSTS.DELETE_SLIDER_BY_ID,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateSlider = data => {
    const requestData = {
        url: _urlSlider,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SLIDER_CONSTS.CREATE_SLIDER,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ ստեղծվեց!"
                })
                history.push('/dashboard')
            })
            .catch(() => {
            })
    }
};

export const UpdateSliderById = (id, data) => {
    const requestData = {
        url: `${_urlSlider}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SLIDER_CONSTS.UPDATE_SLIDER_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ փոփոխվեց!"
                })
                history.push('/dashboard')
            })
            .catch(() => {
            })
    }
};

export const UpdateSlidersPositions = items => {
    const requestData = {
        url: _urlSlider,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SLIDER_CONSTS.UPDATE_SLIDERS_POSITIONS
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};
