import {CHARITY_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    charityList: {
        itemsList: [],
        hasMore: false,
    },
    productById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHARITY_CONSTS.SET_CHARITY_LIST:
            console.log('set')
            return {
                ...state,
                charityList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.charityList.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore
                }
            };
        case CHARITY_CONSTS.UPDATE_CHARITY_ITEM:
            return {
                ...state,
                charityList: {
                    ...state.charityList,
                    itemsList: state?.charityList?.itemsList?.map(product => product.id === action.payload.id ?
                        action.payload : product)
                }
            };
        case CHARITY_CONSTS.DELETE_CHARITY_ITEM:
            return {
                ...state,
                charityList: {
                    ...state.charityList,
                    itemsList: state.charityList.itemsList.filter(product => product.id !== action.payload),
                    count: state.charityList.count - 1
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
