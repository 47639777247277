import {request, _urlCharity} from "../api";
import {CHARITY_CONSTS, UTIL_CONSTS} from "../constants";
import {BASE_GET_LIMIT} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import swal from "sweetalert";

export const GetCharityList = ({
                                reset = true,
                                offset = 0,
                                limit = BASE_GET_LIMIT,
                            } = {}) => {
    let url = `${_urlCharity}?offset=${offset}&limit=${limit}`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: CHARITY_CONSTS.SET_CHARITY_LIST,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const getCharityById = (id) => {
    let url = `${_urlCharity}/${id}`;
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                return data;
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const CreateCharityItem = (data) => {
    const requestData = {
        url: _urlCharity,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Բարեգործությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/charity')
            }).catch((error) => {
                if( error?.response?.status === 409){
                    swal({
                        title: 'Զգուշացում',
                        text: `Ալագը արդեն օգտագործվել է`,
                        icon: "warning",
                        button: 'Լավ'
                    });
                }
            })
    }
};

export const UpdateCharityItemData = (id, data) => {
    const requestData = {
        url: `${_urlCharity}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: CHARITY_CONSTS.UPDATE_CHARITY_ITEM,
                    payload: data
                });
                notificationSuccess({
                    description: "Բարեգործությունը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: '/charity',
                })
            }).catch((error) => {
                if( error?.response?.status === 409){
                    swal({
                        title: 'Զգուշացում',
                        text: `Ալագը արդեն օգտագործվել է`,
                        icon: "warning",
                        button: 'Լավ'
                    });
                }
            })
    }
};

export const DeleteCharityItem = (id) => {
    const requestData = {
        url: `${_urlCharity}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: CHARITY_CONSTS.DELETE_CHARITY_ITEM,
                    payload: id
                });
                notificationSuccess({
                    description: "Բարեգործությունը հաջողությամբ ջնջվեց!"
                })

            })
    }
};
