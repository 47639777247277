// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem, getInputValues} from "../../utils/helperFunctions";
import {DeleteCharityItem, GetCharityList, GetSettings, UpdateSettings,} from "../../redux/actions";
import InfiniteScroll from "react-infinite-scroller";
import {BASE_GET_LIMIT} from "../../constants/constLimitCounts";
import {Switch} from "antd";

class Charity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false
        }
        this.deleteItem = deleteItem.bind(this, props.DeleteCharityItem, 'Բարեգործությունը');
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getValues = this.getValues.bind(this);
    }

    componentDidMount() {
        this.props.GetSettings()
        console.log(this.props.settings,'settings')
        this.props.GetCharityList({
            offset:0,
            limit: BASE_GET_LIMIT,
        });
    }

    getValues(value){
        this.setState({
            loading:true
        })
        this.props.UpdateSettings({charityIsActive:value}) .finally(() => {
            this.props.GetSettings();
            this.setState({
                loading:false
            })
        });
    }

    loadMoreItems(){
        const { hasMore,itemsList } = this.props.charityList;
        hasMore && this.props.GetCharityList({
            reset: false,
            offset:itemsList.length,
            limit: BASE_GET_LIMIT,
        });
    }

    getNestableItems(list, parent = null) {
        const items = [];
        const {mainLanguage} = this.props;
        // eslint-disable-next-line no-unused-expressions
        !!list?.itemsList.length && list?.itemsList.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '- ';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain.path,
                link: {
                    pathname: `/charity/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    render() {
        let {charityList,settings} = this.props;
        let items = this.getNestableItems(charityList);
        return <PageWrapper pageTitle={'Բարեգործություններ'}>
            <div className="charity-switch">
                <label>{settings ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                <Switch checked={settings}
                        loading={this.state.loading}
                        onChange={(checked) => this.getValues(checked)}/>
            </div>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/charity/add"}/>
            <InfiniteScroll
                hasMore={charityList?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'charityList'}
                useWindow={true}
                initialLoad={false}>
                {!!charityList?.itemsList?.length ?
                    <Nestable
                        items={items}
                        confirmChange={()=>false}
                        maxDepth={1}
                        handler={()=>{}}
                        renderItem={NestableItem}
                    />
                    : <div className={'empty-view'}>
                        {/*<img src={defaultEmptyView}/>*/}
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetCharityList,
    DeleteCharityItem,
    GetSettings,
    UpdateSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Charity);
