// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';


import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Slider from "../containers/slider/Slider";
import AddEditSlider from "../containers/slider/AddEditSlider";

import StaticTexts from "../containers/staticTexts/StaticTexts";
import Subscribers from "../containers/subscribers/Subscribers";
import About from "../containers/about/About";
import EditBlock from "../containers/about/EditBlock";
import AddEditCompanies from "../containers/companies/AddEditCompanies";

import Blog from "../containers/blog/Blog";
import AddEditBlog from "../containers/blog/AddEditBlog";

import Charity from "../containers/charity/Charity";
import AddEditCharity from "../containers/charity/AddEditCharity";

import EditCompaniesBlocks from "../containers/companies/EditCompaniesBlocks";
import AddCompaniesBlocks from "../containers/companies/AddCompaniesBlocks";

import Companies from "../containers/companies/Companies";

export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Slider}/>
                    <ProtectedRoute exact path="/dashboard/edit/:id" component={AddEditSlider}/>
                    <ProtectedRoute exact path="/dashboard/add" component={AddEditSlider}/>

                    <ProtectedRoute exact path="/blog" component={Blog}/>
                    <ProtectedRoute exact path="/blog/add" component={AddEditBlog}/>
                    <ProtectedRoute exact path="/blog/edit/:id" component={AddEditBlog}/>

                    <ProtectedRoute exact path="/charity" component={Charity}/>
                    <ProtectedRoute exact path="/charity/add" component={AddEditCharity}/>
                    <ProtectedRoute exact path="/charity/edit/:id" component={AddEditCharity}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/:id" component={EditBlock}/>

                    <ProtectedRoute exact path="/companies" component={Companies}/>
                    <ProtectedRoute exact path="/companies/edit/:id" component={AddEditCompanies}/>
                    <ProtectedRoute exact path="/companies/add" component={AddEditCompanies}/>
                    <ProtectedRoute exact path="/companies/edit/:id/:slug/:index" component={EditCompaniesBlocks}/>
                    <ProtectedRoute exact path="/companies/:id/:slug/add" component={AddCompaniesBlocks}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
