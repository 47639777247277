import {_urlCompanies, request} from "../api";
import {COMPANIES_CONSTS} from "../constants";
import { notificationSuccess } from "../../components/uiElements/Notifications";
import swal from "sweetalert";

export const GetCompaniesData = () => {
    const requestData = {
        url: _urlCompanies,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: COMPANIES_CONSTS.SET_COMPANIES_DATA,
                    payload: data
                });
            })
    }
};

export const GetCompaniesById = (id) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: COMPANIES_CONSTS.GET_COMPANIES_BY_ID,
                    payload: data
                });
            })
    }
};

export const UpdateCompaniesData = (id,data) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPANIES_CONSTS.SET_COMPANIES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};

export const CreateCompaniesItem = data => {
    const requestData = {
        url: _urlCompanies,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPANIES_CONSTS.CREATE_COMPANIES_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ընկերություններ հաջողությամբ ստեղծվեց!"
                })
                return res.data
            })
            .catch((error) => {
                if( error?.response?.status === 409){
                    swal({
                        title: 'Զգուշացում',
                        text: `Ալագը արդեն օգտագործվել է`,
                        icon: "warning",
                        button: 'Լավ'
                    });
                }
            })
    }
};

export const UpdateCompaniesItemData = (id, data) => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: COMPANIES_CONSTS.SET_COMPANIES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            }).catch((error) => {
                if( error?.response?.status === 409){
                    swal({
                        title: 'Զգուշացում',
                        text: `Ալագը արդեն օգտագործվել է`,
                        icon: "warning",
                        button: 'Լավ'
                    });
                }
            })
    }
};

export const DeleteCompaniesItem = id => {
    const requestData = {
        url: `${_urlCompanies}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: COMPANIES_CONSTS.DELETE_COMPANIES_ITEM,
                    payload: id
                });
            })
    }
};
